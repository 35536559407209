<template>
<div>
  <div v-if="isLoaded">
    <v-card class = "mx-auto my-3" elevation="3" :width = "$vuetify.breakpoint.mobile?'80%':'40%'">
      <AccBasicDisplay :user="token"></AccBasicDisplay>
    </v-card>
    <v-card class = "mx-auto my-5" elevation="3" :width = "$vuetify.breakpoint.mobile?'80%':'40%'">
      <v-card-title class = "d-flex justify-center">
        <span><span class="text-uppercase">{{user.group}}</span> Account</span>
      </v-card-title>
      <!-- guide: https://vuetifyjs.com/en/components/expansion-panels/#accordion-->
      <v-expansion-panels hover v-model='tabOpened'>
        <v-expansion-panel v-if="isCharity">
          <v-expansion-panel-header>
            PWA Setting
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row><v-btn class="ma-auto mt-3 primary" large @click="toPwaView" >PWA Setting Page</v-btn></v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Edit Account Details</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="nameform">
            <!--if the user is a giver-->
            <NameField label="First Name" :value.sync="user.firstName" :isApplicable="user.group==='giver'"></NameField>
            <NameField label="Last Name" :value.sync="user.lastName" :isApplicable="user.group==='giver'"></NameField>
            <!--if the user is a charity-->
            <NameField label="Charity Name" :value.sync="user.name" :isApplicable="user.group==='charity'"></NameField>
            <PhoneField label="Phone" :value.sync="user.phoneNumber" :isApplicable="user.group==='charity'"
              hint="Make sure it is a contact number in WhatsApp or Wechat"></PhoneField>
            <ChipBox label ="Charity Categories" hint="Create 1-5 to Identify Yourself"
              :choices.sync ="user.categories"  :isApplicable="user.group==='charity'">
            </ChipBox>
            <GeolocationField :isApplicable="user.group==='charity'"
              :geolocation.sync="user.geolocation">
            </GeolocationField>
            <v-textarea solo  :label="`Tell About ${user.lastName}`" v-model="user.bio"> </v-textarea>
            </v-form>
            <v-row>
              <v-btn class="mx-auto" large color="primary" :loading='btnLoading'
              @click="updateAcc('nameform')">Apply</v-btn>
            </v-row>
            <v-row justify='center' v-if= "updateMessage.displayed">
              <span :class="`text-body-3 text-center ${updateMessage.color}--text`">
                <v-icon small :color="updateMessage.color">mdi-check-circle</v-icon>
                {{updateMessage.message}}
              </span>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Edit Email</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="emailform">
            <v-row>
              <v-alert dense outlined type="info" color="secondary" class="text-body-2 ml-3">
                You'll need to verify the new email if you update your email</v-alert>
            </v-row>
            <EmailField :useremail.sync="user.email" isValidated="true" :exclusions="[user.email]"></EmailField>
            <v-row>
              <v-btn class="mx-auto " large color="primary" :loading="btnLoading"
              @click="updateAcc('emailform')">Apply</v-btn>
            </v-row>
            <v-row justify='center' v-if= "updateMessage.displayed">
              <span :class="`text-body-3 text-center ${updateMessage.color}--text`">
                <v-icon small color="updateMessage.color">mdi-check-circle</v-icon>
                {{updateMessage.message}}
              </span>
            </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Upload Profile Picture</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-file-input solo chips clearable show-size placeholder="Click to upload" truncate-length="10"
              accept="image/*" v-model="pictureObj">
            </v-file-input>
            <v-row>
              <v-btn class="mx-auto" large color="primary" :loading="btnLoading" @click="updatePicture">Apply</v-btn>
            </v-row>
            <v-row justify='center' v-if= "updateMessage.displayed">
              <span :class="`text-body-3 text-center ${updateMessage.color}--text`">
                <v-icon small :color="updateMessage.color">mdi-check-circle</v-icon>
                {{updateMessage.message}}
              </span>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Reset Password</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="center" class="pt-2">
              <v-btn color="error" @click='confirmationDialogOpen=true'>Rest Password</v-btn>
            </v-row>
            <v-dialog v-model="confirmationDialogOpen" max-width="80%">
              <v-card class="pa-8">
              <div>
              <v-row justify="center" class="text-center">
                You'll be logged out and reset password in the next login</v-row>
              <v-row justify="center" class="py-2"><v-btn color="error" @click="resetPass">Confirm to Reset</v-btn></v-row>
              </div>
              </v-card>
            </v-dialog>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="user.group==='charity'">
          <v-expansion-panel-header>Donation Account</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-alert dense outlined type="info" color="secondary" class="text-body-2 ml-3 mt-7">
                ioCharity uses Stripe as Secure Transaction Broker for Online donation.
              <br><kbd>No platform fee</kbd> is involved. All transaction fees involved depend on Stripe
            </v-alert>
            <v-row justify="center">
            <v-btn color="primary" @click="setupStripe" :loading="stripe.setupLoading">
              {{stripe.message}}<v-icon clas="mx-2">mdi-arrow-right-bold</v-icon></v-btn>
            </v-row>
            <v-row justify='center' v-if= "updateMessage.displayed">
              <span :class="`mt-7 text-body-3 text-center ${updateMessage.color}--text`">
                <v-icon small color="updateMessage.color">mdi-check-circle</v-icon>
                {{updateMessage.message}}
              </span>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
  <div v-else>
    <v-skeleton-loader type="card-avatar, list-item"  class = "mx-auto my-10"
      max-height="50vh" max-width="60vw"></v-skeleton-loader>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import AccBasicDisplay from '@/components/account/AccBasicDisplay.vue'
import EmailField from '@/components/fields/EmailField.vue'
import NameField from '@/components/fields/NameField.vue'
import PhoneField from '@/components/fields/PhoneField.vue'
import ChipBox from '@/components/fields/ChipBox.vue'
import GeolocationField from '@/components/fields/GeolocationField.vue'
import { mapGetters } from 'vuex'
import router from '@/router'
export default {
  components: {
    AccBasicDisplay, EmailField, NameField, PhoneField, ChipBox, GeolocationField
  },
  data () {
    return {
      isLoaded: false,
      user: {},
      pictureObj: null,
      tabOpened: false,
      confirmationDialogOpen: false,
      btnLoading: false,
      stripe: {
        setupLoading: false,
        message: 'Connect with Stripe'
      },
      updateMessage: {
        displayed: false,
        message: '',
        color: 'primary'
      }
    }
  },
  computed: {
    token: function () {
      if (Vue.$keycloak.authenticated) {
        return Vue.$keycloak.tokenParsed
      } else {
        return {}
      }
    },
    isCharity: function () {
      return Vue.$keycloak.tokenParsed
        .groups.includes('charity')
    },
    ...mapGetters('userStore', ['loginConfirm'])
  },
  methods: {
    toPwaView () {
      const segment = Vue.$pathConverter.getFirstSegment()
      this.$router.push({ name: 'PwaSettingView', param: segment })
    },
    updateAcc (form) {
      // eslint-disable-next-line no-undef
      if (this.$refs[form].validate()) {
        this.btnLoading = true
        const data = {}
        if (form === 'nameform') {
          this.copyTo(data, this.user, this.user.group)
          delete data.email
          delete data.picture
        } else if (form === 'emailform') {
          data.email = this.user.email
          data.group = this.user.group
          if (data.email === Vue.$keycloak.tokenParsed.email) {
            this.updateMessage.message = 'The email does not change. Please issue a new email.'
            this.updateMessage.color = 'error'
            this.updateMessage.displayed = true
            this.btnLoading = false
            return
          }
        }
        Vue.$privateApi.put('/account/update', data).then(res => {
          // console.log(res.status)
          this.updateMessage.message = 'You have sucessfully updated your account.'
          this.updateMessage.color = 'success'
          Vue.$keycloak.updateToken().then((isRefreshed) => {
            // console.log(`Refresh User Access Token: ${isRefreshed}`)
          }).catch((err) => {
            console.error(`Token is still valid. No refresh is done: ${err}`)
          })
        }).catch(() => {
          console.error(`Fail to perform update for ${form}`)
          this.updateMessage.message = 'No update is performed. Please try later.'
          this.updateMessage.color = 'error'
        }).finally(() => {
          this.btnLoading = false
          this.updateMessage.displayed = true
        })
      }
    },
    /**
     * Upload user chosen picture. The size of th picture should be <=1MiB
     */
    updatePicture () {
      if (this.pictureObj && this.pictureObj.size <= 1048576 && this.pictureObj.type.includes('image')) {
        this.btnLoading = true
        const image = new FormData()
        // console.log(image)
        image.append('picture', this.pictureObj)
        Vue.$privateApi.post('/account/update/picture', image,
          {
            headers: { 'Content-Type': 'multi-part/form-data' }
          }).then((response) => {
          this.updateMessage.message = 'You have sucessfully updated your account. Reload your App To reflect the change.'
          this.updateMessage.color = 'success'
          Vue.$keycloak.tokenParsed.picture = response.headers.location
          // console.log(response.headers.location)
        }).catch(() => {
          console.error('Fail to upload picture')
          this.updateMessage.message = 'No update is performed. Please try later.'
          this.updateMessage.color = 'error'
        }).finally(() => {
          this.btnLoading = false
          this.updateMessage.displayed = true
        })
      } else {
        this.updateMessage.message = 'It should be an image within 1MiB'
        this.updateMessage.color = 'error'
        this.updateMessage.displayed = true
      }
    },
    resetPass () {
      Vue.$privateApi.put('/account/resetpass')
        .then(() => {
          // console.log('Password has been reset. Logout user.')
          const redirect = window.location.href.replace(
            window.location.pathname,
          `/${Vue.$pathConverter.getFirstSegment()}/`
          )
          Vue.$keycloak.logout({ redirectUri: redirect })
        }).catch(() => {
          console.error('Fail to reset Password.')
          this.confirmationDialogOpen = false
          this.updateMessage.message = 'No reset is performed. Please try later.'
          this.updateMessage.color = 'error'
          this.updateMessage.displayed = true
        })
    },
    copyTo (newObj, oldObj, group) {
      newObj.email = oldObj.email
      newObj.group = group
      if (newObj.group === 'giver') {
        newObj.firstName = oldObj.firstName
        newObj.lastName = oldObj.lastName
      } else {
        newObj.name = oldObj.name
        newObj.phoneNumber = oldObj.phoneNumber
        if (oldObj.geo_lat) {
          newObj.geolocation = {
            lat: oldObj.geo_lat,
            lng: oldObj.geo_lng
          }
        } else {
          newObj.geolocation = oldObj.geolocation
        }
        newObj.categories = oldObj.categories
      }
      // picture and bio are not compulsory fields for user
      if ('picture' in oldObj) {
        newObj.picture = oldObj.picture
      } else {
        newObj.picture = null
      }
      if ('bio' in oldObj) {
        newObj.bio = oldObj.bio
      } else {
        newObj.bio = null
      }
    },
    setupStripe () {
      this.stripe.setupLoading = true
      const redirect = `${window.location.href}?from=stripe`
      const url = `/project/charity/donationacc?redirect=${redirect}`
      Vue.$privateApi.post(url)
        .then((response) => {
          const url = response.data
          // console.log(url)
          const stripPop = window.open(url)
          this.stripe.message = 'Update Stripe Account'
          window.addEventListener('message', (e) => {
            if (e.data.stripe === true) {
              stripPop.close()
              this.updateMessage.message =
                'You has successfully setup your Stripe. Click again to update the Account'
              this.updateMessage.color = 'success'
              this.updateMessage.displayed = true
            }
          })
        }).finally(() => {
          this.stripe.setupLoading = false
        })
    }
  },
  created () {
    // if this page is loaded directly in a child window and has a parent window (PWA),
    // it is from Stripe's redirect, it posts message to parent to close itself
    if (window.opener) {
      const message = { stripe: true }
      window.opener.postMessage(message, window.location.href)
    }
    // if loginConfirm has been already set, load this page or error page
    // if not, leave it to watch function for subsequent update
    if (this.loginConfirm) {
      Vue.$privateApi.get('/project/charity/donationacc/exist').then(() => {
        this.stripe.message = 'Update Stripe'
      }).catch(() => {
        this.stripe.message = 'Connect with Stripe'
      })
      this.isLoaded = true
      const token = Vue.$keycloak.tokenParsed
      const groups = token.groups
      this.copyTo(this.user, token, groups[0])
    } else if (this.loginConfirm === false) {
      const firstSeg = Vue.$pathConverter.getFirstSegment()
      console.error(`Opps! Unauthorised access. Route to <error page?401=${window.location.href}> of ${firstSeg}`)
      router.push({
        name: 'Error',
        params: { root: firstSeg },
        query: { 401: window.location.href }
      })
    } else {
      this.isLoaded = false
    }
  },
  watch: {
    tabOpened () {
      // always close the display either open or close
      //  since this only appears when button is clicked
      this.updateMessage.displayed = false
    },
    loginConfirm (newloginConfirm) {
      // in subsequent change in loginConfirm
      // when loginConfirm is confirmed, it is either true or false
      // enter if true and reroute to error page if false
      const firstSeg = Vue.$pathConverter.getFirstSegment()
      if (newloginConfirm) {
        this.isLoaded = true
        const token = Vue.$keycloak.tokenParsed
        const groups = token.groups
        this.copyTo(this.user, token, groups[0])
      } else {
        router.push({
          name: 'Error',
          params: { root: firstSeg },
          query: { 401: window.location.href }
        })
      }
    }
  }
}
</script>
